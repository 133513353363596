import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// radix
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
// components
import { BaseField } from "..";
import { CardContainer } from "../../templates";
/**
 * RadioGroup allows the user to select a boolean value.
 */
export const RadioGroup = ({ id, label, description, tooltip, value, onChange, isRequired = false, isHideTitle = false, ariaLabel, options, isDisabled = false, maxColumns = 1, }) => {
    const sortedOptions = useMemo(() => {
        return options === null || options === void 0 ? void 0 : options.sort((a, b) => ((a === null || a === void 0 ? void 0 : a.order) || 0) - ((b === null || b === void 0 ? void 0 : b.order) || 0));
    }, [options]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsx(RadioGroupRoot, Object.assign({ value: value, onValueChange: onChange, "aria-label": ariaLabel, "aria-labelledby": id, disabled: isDisabled }, { children: _jsx(CardContainer, Object.assign({ maxColumns: maxColumns, size: 'sm' }, { children: sortedOptions === null || sortedOptions === void 0 ? void 0 : sortedOptions.map((option) => (_jsxs(RadioGroupItem, Object.assign({ value: option.value, id: option.value, "$isDisabled": isDisabled }, { children: [_jsx(RadioGroupSelector, Object.assign({ "$isDisabled": isDisabled }, { children: _jsx(RadioGroupIndicator, { "$isDisabled": isDisabled }) })), _jsx(Label, Object.assign({ htmlFor: option.value, "$isDisabled": isDisabled }, { children: option.label }))] }), option.value))) })) })) })));
};
const RadioGroupRoot = styled(RadixRadioGroup.Root) `
  display: flex;
  gap: ${(props) => props.theme.spacing[16]};
`;
const RadioGroupItem = styled(RadixRadioGroup.Item) `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;

  background: ${(props) => props.theme.color.neutral[10]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing[24]}
    ${(props) => props.theme.spacing[16]};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.color.neutral[30]};
  }

  &:focus {
    outline: 3px solid ${(props) => props.theme.color.primary[20]};
  }

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[30]};
      color: ${props.theme.color.neutral[50]};
      border: 1px solid ${props.theme.color.neutral[50]};
      cursor: default;
    `}
`;
const RadioGroupSelector = styled.div `
  background: ${(props) => props.theme.color.neutral[10]};

  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 99999px;
  border: 1px solid ${(props) => props.theme.color.neutral[60]};

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[40]};
    `}
`;
const RadioGroupIndicator = styled(RadixRadioGroup.Indicator) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    display: block;
    min-width: 10px;
    max-width: 10px;
    min-height: 10px;
    max-height: 10px;
    border-radius: 99999px;
    background: ${(props) => props.theme.color.primary[30]};
  }

  ${(props) => props.$isDisabled &&
    css `
      &::after {
        background: ${props.theme.color.neutral[60]};
      }
    `}
`;
const Label = styled.label `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  cursor: pointer;

  ${(props) => props.$isDisabled &&
    css `
      color: ${props.theme.color.neutral[60]};
      cursor: default;
    `}
`;
RadioGroup.displayName = 'RadioGroup';
export default RadioGroup;
