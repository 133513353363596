// react
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// store
import {
  useCreateMemberMutation,
  useKindeAuthenticateMutation,
  useMigrateAuthenticateMutation,
  useUpdateCareCircleNamesMutation,
  useUpdateIsOnboardedMutation,
} from 'store/api/iam';
import { useGenerateActionPlanMutation } from 'store/api/actionPlan';
import { useUpdateMemberMutation } from 'store/api/careCircle';
import { useGetAllRelationshipQuery } from 'store/api/relationship';
import { useGetAllPersonalizationQuery } from 'store/api/personalization';
import { getIsAuthenticated } from 'store/slices/iamSlice';
import { useSelector } from 'store';
import { apiUrl } from 'store/api';

// hooks
import { useApp, useCareCircle, useCareProfileQuestions } from 'hooks';

// models
import { CareCircleMember } from '@karehero/models';

// constants
import { MemberRole } from '@karehero/models';

// components
import {
  AppStore,
  Authenticate as AuthenticatePage,
  Dialog,
  OnboardingActionType,
  OnboardingActivatedStart,
  OnboardingCarousel,
  OnboardingEditor,
  OnboardingFlow,
  OnboardingLoading,
  OnboardingLoadingDone,
} from '@karehero/llama';

import { FieldType } from '@karehero/llama/src/components/organisms/EditorFields/fieldHelper';

// launch darkly
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { createLdContext } from 'launchDarkly';

// config
import {
  onboardingActivatedStartConfig,
  onboardingDoneConfig,
  onboardingLoadingConfig,
  onboardingLoadingDoneConfig,
  onboardingMemberCarouselConfig,
} from 'config/onboarding';

// validation
const validateNotEmpty = (value?: string) =>
  ((value && value.length) ||
    (typeof value === 'boolean' && value !== undefined) ||
    0) > 0;

const validateNotEmptyArray = (value?: string) =>
  JSON.parse(value || '[]').length > 0;

/**
 * OnboardingMemberCreate takes the user through the onboarding flow for a care concierge.
 */
const OnboardingMemberCreate = () => {
  // hooks
  const flags = useFlags();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [createMember] = useCreateMemberMutation();
  const [updateCareCircleNames] = useUpdateCareCircleNamesMutation();
  const [updateIsOnboarded] = useUpdateIsOnboardedMutation();
  const ldClient = useLDClient();
  const [updateMember] = useUpdateMemberMutation();
  const [kindeAuthenticate] = useKindeAuthenticateMutation();
  const [migrateAuthenticate] = useMigrateAuthenticateMutation();
  const [generateActionPlan] = useGenerateActionPlanMutation();
  const { data: relationshipOptions } = useGetAllRelationshipQuery();
  const { data: personalizationOptions } = useGetAllPersonalizationQuery();
  const { isApp } = useApp();
  const { currentCareCircle, currentCareCircleMember } = useCareCircle();

  const careProfileQuestionsOptions = useMemo(
    () => ({
      questionIDs: [
        'care-recipient-is-over-65',
        'care-recipient-medical-conditions',
        'care-recipient-location',
        'care-recipient-fund-type',
        'care-recipient-receive-attendance-allowance',
        'care-recipient-power-of-attorney',
      ],
      isSkipAnswers: true,
    }),
    [],
  );

  const {
    careCircleId,
    createManyAnswer,
    questions,
    answers,
    setAnswers,
    answersRequest,
  } = useCareProfileQuestions(careProfileQuestionsOptions);

  // refs
  const isRequested = useRef(false);

  // state
  const [isAppStoreOpen, setIsAppStoreOpen] = useState(!isApp);
  const [caregiverFirstName, setCaregiverFirstName] = useState<{
    value: string;
  }>();
  const [careRecipientFirstName, setCareRecipientFirstName] = useState<{
    value: string;
  }>();
  const [relationship, setRelationship] = useState<{ value: string }>({
    value: '',
  });
  const [personalization, setPersonalization] = useState<{ value: string }>({
    value: '[]',
  });

  // methods
  // set search params
  const handleNext = useCallback(
    (configID: string, subConfigID: string) => {
      setSearchParams({ configID, subConfigID });
    },
    [setSearchParams],
  );

  // launch darkly
  const handleAnalyticsIdentify = useCallback(
    (res: any) => {
      const newContext = createLdContext(res.data);
      ldClient?.identify(newContext);
    },
    [ldClient],
  );

  // relationships
  const handleUpdateRelationship = useCallback(async (): Promise<boolean> => {
    const res = await updateMember({
      ...(currentCareCircleMember as CareCircleMember),
      careCircleId: careCircleId || '',
      careRecipientAccountRelationshipId: relationship.value,
    });
    if (!('data' in res)) {
      console.error('Failed to update relationship', res.error);
      return false;
    }

    return true;
  }, [relationship, currentCareCircleMember, updateMember, careCircleId]);

  // personalization
  const handleUpdatePersonalization =
    useCallback(async (): Promise<boolean> => {
      const res = await updateMember({
        ...(currentCareCircleMember as CareCircleMember),
        careCircleId: careCircleId || '',
        personalization: JSON.parse(personalization.value).map((x: any) => ({
          id: x,
        })),
      });
      if (!('data' in res)) {
        console.error('Failed to update personalization', res.error);
        return false;
      }

      return true;
    }, [personalization, currentCareCircleMember, careCircleId, updateMember]);

  const handleUpdateCareProfile = useCallback(async (): Promise<boolean> => {
    const res = await createManyAnswer(answersRequest);
    if (!('data' in res)) {
      console.error('Failed to update care profile', res.error);
      return false;
    }

    return true;
  }, [answersRequest, createManyAnswer]);

  // generate action plan
  const handleGenerateActionPlan = useCallback(async () => {
    const res = await generateActionPlan();
    if (!('data' in res)) {
      console.error('Failed to generate action plan', res.error);
      return false;
    }

    return true;
  }, [generateActionPlan]);

  // update account
  const handleUpdateCareCircleNames =
    useCallback(async (): Promise<boolean> => {
      const res = await updateCareCircleNames({
        caregiverFirstName: caregiverFirstName?.value || '',
        careRecipientFirstName: careRecipientFirstName?.value || '',
      });
      if (!('data' in res)) {
        console.error('Failed to update account', res.error);
        return false;
      }

      return true;
    }, [caregiverFirstName, careRecipientFirstName, updateCareCircleNames]);

  // create account
  const handleCreate = useCallback(async (): Promise<boolean> => {
    const res = await createMember();
    if (!('data' in res)) {
      console.error('Failed to create member', res.error);
      return false;
    }

    handleAnalyticsIdentify(res);

    return true;
  }, [createMember, handleAnalyticsIdentify]);

  // complete
  const handleComplete = useCallback(async () => {
    await updateIsOnboarded();
    navigate('/action-plan/book-call-with-care-expert?isHideBack=true');
    window.location.reload();
  }, [updateIsOnboarded, navigate]);

  // memos
  const currentCareRecipientName = useMemo(() => {
    if (!currentCareCircle?.careRecipientAccount) return null;

    let name = currentCareCircle.careRecipientAccount.firstName;
    if (currentCareCircle.careRecipientAccount.lastName) {
      name += ` ${currentCareCircle.careRecipientAccount.lastName}`;
    }

    return name;
  }, [currentCareCircle]);

  /**
   * onboardingConfig is the primary configuration for the onboarding flow.
   *
   * Any other configurations are for role specific onboarding flows.
   *
   * It is a list of OnboardingFlowConfig objects. (See OnboardingFlow.tsx in
   * the @karehero/llama package for more information.)
   *
   * Each config item has an id, a Component, and a config array.
   *
   * The config array are steps in a single onboarding flow.
   */

  const defaultConfig = useMemo(
    () => [
      {
        id: 'carousel',
        Component: OnboardingCarousel,
        config: onboardingMemberCarouselConfig,
      },
      {
        id: 'activated-start',
        Component: OnboardingActivatedStart,
        config: onboardingActivatedStartConfig,
      },
      {
        id: 'care-giver-first-name',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateCareCircleNames,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: "What's your name?",
            subTitle: 'This is so we know what to call you in your account.',
            value: caregiverFirstName,
            onChange: setCaregiverFirstName,
            fieldDef: {
              label: 'Your First Name',
              field: {
                type: FieldType.Text,
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'who-are-you-caring-for',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateRelationship,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: 'Who are you caring for?',
            value: relationship,
            onChange: setRelationship,
            fieldDef: {
              label: `What is your relationship to ${
                currentCareRecipientName || 'the care recipient'
              }?`,
              field: {
                type: FieldType.Select,
                options: relationshipOptions?.map((x) => ({
                  value: x.id,
                  label: x.name,
                })),
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'loading-done',
        Component: OnboardingLoadingDone,
        config: onboardingDoneConfig,
      },
    ],
    [
      caregiverFirstName,
      currentCareRecipientName,
      relationship,
      relationshipOptions,
      handleUpdateCareCircleNames,
      handleUpdateRelationship,
    ],
  );

  const primaryCaregiverConfig = useMemo(
    () => [
      {
        id: 'carousel',
        Component: OnboardingCarousel,
        config: onboardingMemberCarouselConfig,
      },
      {
        id: 'activated-start',
        Component: OnboardingActivatedStart,
        config: onboardingActivatedStartConfig,
      },
      {
        id: 'care-giver-first-name',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateCareCircleNames,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: "What's your name?",
            subTitle: 'This is so we know what to call you in your account.',
            value: caregiverFirstName,
            onChange: setCaregiverFirstName,
            fieldDef: {
              label: 'Your First Name',
              field: {
                type: FieldType.Text,
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'what-can-we-help-you-with',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdatePersonalization,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: 'What can we help you with?',
            subTitle:
              'Select as many as you need. Don’t worry if you’re not sure, we’ll help you work out next steps.',
            value: personalization,
            onChange: setPersonalization,
            fieldDef: {
              field: {
                type: FieldType.MultiSelectBox,
                options: personalizationOptions?.map((x) => ({
                  value: x.id,
                  label: x.label,
                })),
                size: 'sm',
                maxColumns: 1,
                isCustom: true,
              },
              validation: validateNotEmptyArray,
            },
          },
        ],
      },
      {
        id: 'who-are-you-caring-for',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateRelationship,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: 'Who are you caring for?',
            value: relationship,
            onChange: setRelationship,
            fieldDef: {
              label:
                'The person you are caring for or thinking about caring for in the future',
              field: {
                type: FieldType.Select,
                options: relationshipOptions?.map((x) => ({
                  value: x.id,
                  label: x.name,
                })),
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'care-recipient-first-name',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateCareCircleNames,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: `What is your ${
              relationshipOptions?.find((r) => r.id === relationship.value)
                ?.name || 'loved one'
            }'s name?`,
            subTitle: 'This is so we know how to refer to them.',
            value: careRecipientFirstName,
            onChange: setCareRecipientFirstName,
            fieldDef: {
              label: 'Their first name',
              field: {
                type: FieldType.Text,
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'care-profile-questions',
        Component: OnboardingEditor,
        onBeforeNext: async () => {
          let res = await handleUpdateCareProfile();
          if (!res) {
            console.error('Failed to update care profile');
            return false;
          }

          res = await handleGenerateActionPlan();
          if (!res) {
            console.error('Failed to generate action plan');
            return false;
          }
          return true;
        },
        isProgressBar: true,
        config: [
          ...Object.keys(questions.fields).map((questionId) => ({
            id: questionId,
            title: questions.fields[questionId].label,
            subTitle: questions.fields[questionId].supportText,
            tooltip: questions.fields[questionId].tooltip,
            value: { value: answers[questionId] },
            onChange: (value: any) => {
              setAnswers({
                ...answers,
                [questionId]: value.value,
              });
            },
            fieldDef: {
              ...questions.fields[questionId],
              isHideTitle: true,
              validation: ['care-recipient-medical-conditions'].includes(
                questionId,
              )
                ? () => true
                : validateNotEmpty,
              isRequired: false,
            },
          })),
        ],
      },
      {
        id: 'loading',
        Component: OnboardingLoading,
        config: onboardingLoadingConfig,
        onStart: (handleClick: (action: OnboardingActionType) => void) =>
          setTimeout(
            () => handleClick(OnboardingActionType.Next),
            2000 + Math.random() * 1000,
          ),
      },
      {
        id: 'loading-done',
        Component: OnboardingLoadingDone,
        config: onboardingLoadingDoneConfig,
      },
    ],
    [
      caregiverFirstName,
      careRecipientFirstName,
      relationship,
      relationshipOptions,
      personalization,
      personalizationOptions,
      handleUpdateCareCircleNames,
      handleUpdateRelationship,
      handleUpdatePersonalization,
      handleUpdateCareProfile,
      handleGenerateActionPlan,
      answers,
      questions,
      setAnswers,
    ],
  );

  const onboardingConfig = useMemo(() => {
    switch (currentCareCircleMember?.roles[0]?.name) {
      case MemberRole.PrimaryCaregiver:
        return primaryCaregiverConfig;
      default:
        return defaultConfig;
    }
  }, [currentCareCircleMember, primaryCaregiverConfig, defaultConfig]);

  // effects
  useEffect(() => {
    if (searchParams.get('authed')) {
      isRequested.current = true;
      return;
    }

    const code = searchParams.get('code');
    if (!code) {
      if (!isAuthenticated) navigate('/sign-in');
      return;
    }
    if (isRequested.current) return;

    const migration = !!searchParams.get('migration');

    (async () => {
      isRequested.current = true;
      const res = await kindeAuthenticate({ code, isMigration: migration });
      if ('error' in res) {
        console.error('Failed to register Kinde', res.error);
        window.location.href = '/';
        return;
      }

      if (migration) {
        const res2 = await migrateAuthenticate();
        if ('error' in res2) {
          console.error('Failed to authenticate migration', res2.error);
          window.location.href = '/';
          return;
        }
      } else {
        const res2 = await handleCreate();
        if (!res2) {
          console.error('Failed to create member');
          window.location.href = '/';
          return;
        }
      }

      setSearchParams({ authed: 'true', migration: `${migration}` });
    })();
  }, [
    searchParams,
    setSearchParams,
    navigate,
    kindeAuthenticate,
    migrateAuthenticate,
    isRequested,
    handleCreate,
    isAuthenticated,
  ]);

  return isAuthenticated ? (
    <>
      <OnboardingFlow
        config={onboardingConfig}
        onNext={handleNext}
        onComplete={handleComplete}
      />
      {flags.appStoreSignpost && (
        <Dialog
          variant='full'
          isOpen={isAppStoreOpen}
          setIsOpen={setIsAppStoreOpen}
        >
          <AppStore storeUrl={`${apiUrl}/v1/app-store`} />
        </Dialog>
      )}
    </>
  ) : (
    <AuthenticatePage />
  );
};

OnboardingMemberCreate.displayName = 'OnboardingMemberCreate';
export default OnboardingMemberCreate;
