import api from '.';
import {
  CareCircle,
  CareCircleMember,
  Message,
  SetupCareCircle,
} from '@karehero/models';

export const careCircleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<CareCircle[], void>({
      query: () => '/care-circle',
      providesTags: ['CareCircles'],
    }),
    getCareCircle: builder.query<CareCircle, string>({
      query: (id) => ({ url: `care-circle/${id}` }),
      providesTags: ['CareCircles'],
    }),
    getSessionCareCircle: builder.query<CareCircle, void>({
      query: () => '/care-circle/session',
      providesTags: ['CareCircles'],
    }),
    getSessionCareCircleMember: builder.query<CareCircleMember, void>({
      query: () => '/care-circle/member',
      providesTags: ['CareCircles'],
    }),
    switchCareCircle: builder.mutation<CareCircle, string>({
      query: (id) => ({
        url: `/care-circle/${id}/switch`,
        method: 'POST',
      }),
      invalidatesTags: ['CareCircles'],
    }),
    inviteCareCircle: builder.mutation<Message, { email: string }>({
      query: (payload) => ({
        url: `/care-circle/invite`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CareCircles'],
    }),
    getByAccount: builder.query<CareCircle[], void>({
      query: () => '/care-circle/account',
      providesTags: ['CareCircles'],
    }),
    create: builder.mutation<CareCircle, SetupCareCircle>({
      query: (careCircle) => ({
        url: '/care-circle',
        method: 'POST',
        body: careCircle,
      }),
      invalidatesTags: ['CareCircles'],
    }),
    createMember: builder.mutation<CareCircle, CareCircleMember>({
      query: ({ careCircleId, account }) => ({
        url: `/care-circle/${careCircleId}/member`,
        method: 'POST',
        body: account,
      }),
      invalidatesTags: ['CareCircles'],
    }),
    updateMember: builder.mutation<CareCircleMember, CareCircleMember>({
      query: (careCircleMember) => ({
        url: `/care-circle/member`,
        method: 'PUT',
        body: careCircleMember,
      }),
      invalidatesTags: ['CareCircles'],
    }),
  }),
});

export const {
  useGetAllQuery,
  useGetCareCircleQuery,
  useGetSessionCareCircleQuery,
  useGetSessionCareCircleMemberQuery,
  useSwitchCareCircleMutation,
  useInviteCareCircleMutation,
  useLazyGetCareCircleQuery,
  useCreateMutation,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useLazyGetByAccountQuery,
} = careCircleApi;

export default careCircleApi;
