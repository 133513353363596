import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
// theme
import styled from 'styled-components';
// components
import { Table } from '../Table/Table';
import { ProfileSummary } from "../../molecules";
// tanstack
import { createColumnHelper } from '@tanstack/react-table';
// columns
const columnHelper = createColumnHelper();
/**
 * AccountList is a component that renders password and confirm password input field with
 * a submit button.
 */
export const AccountList = ({ members, onPreview, onInvite, }) => {
    // memos
    const rows = useMemo(() => members.map((member) => ({ member })), [members]);
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('member', {
                cell: (info) => {
                    var _a, _b, _c;
                    const member = info.getValue();
                    return (_jsx(ProfileSummary, Object.assign({}, member.account, { name: `${member.account.firstName || ''} ${member.account.lastName || ''}`, role: ((_a = member.roles[0]) === null || _a === void 0 ? void 0 : _a.name) || '', fallback: `${((_b = member.account.firstName) === null || _b === void 0 ? void 0 : _b[0]) || ''} ${((_c = member.account.lastName) === null || _c === void 0 ? void 0 : _c[0]) || ''}` })));
                },
            }),
        ];
    }, []);
    const defaultAction = {
        label: '',
        iconLeft: 'chevron-right',
        buttonSize: 'lg',
        buttonVariant: 'link',
        // TODO[care-circle]: invert this once we join invited members with care circles
        isHidden: (row) => !!row.member.isRegistered,
        onPress: (row) => onPreview(row.member),
    };
    const actions = useMemo(() => {
        return [
            {
                label: 'Reinvite',
                isHidden: (row) => !row.member.isRegistered,
                onPress: (row) => {
                    if (!row.member.account.email)
                        return;
                    onInvite(row.member.account.email);
                },
            },
            defaultAction,
        ];
    }, []);
    return (_jsx(StyledTable, { columns: columns, rows: rows, actions: actions, defaultAction: defaultAction, isHeader: false, isSearchable: false, isPaginated: false, isBorder: false, isTotalRows: false, noDataMsg: `No accounts to display` }));
};
const StyledTable = styled(Table) `
  overflow-y: auto;
  width: 100%;
`;
AccountList.displayName = 'AccountList';
export default AccountList;
