// react
import { useMemo } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import styled from 'styled-components';

// components
import {
  Banner,
  HubSpotChat,
  Icon,
  NavBar,
  NavBarTemplate,
  theme,
  Tooltip,
} from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount, getIsAuthenticated } from 'store/slices/iamSlice';
import { apiUrl } from 'store/api';

// hooks
import { useApp, useKinde, useWindowSize } from 'hooks';

/**
 * Root is the main component of the application.
 */
const Root = () => {
  // hooks
  const currentOutlet = useOutlet();
  const windowSize = useWindowSize();
  const location = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const account = useSelector(getAccount);
  const { isApp, isApple } = useApp();
  const { signOut } = useKinde();

  // memo
  const isMobile = useMemo(() => {
    return windowSize[0] <= theme.breakpoint.smValue;
  }, [windowSize]);

  const homePath = useMemo(() => {
    if (
      account.accountRole.name !== 'KareHero Administrator' &&
      account.accountRole.name !== 'Care Concierge'
    ) {
      return `/`;
    }
    return '/admin';
  }, [account]);

  const hubspotTrackingUrl = useMemo(() => {
    const env = process.env.REACT_APP_BUILD_ENVIRONMENT ?? 'local';

    const urls: any = {
      local: 'https://js-eu1.hs-scripts.com/142770276.js',
      development: 'https://js-eu1.hs-scripts.com/142770276.js',
      staging: 'https://js-eu1.hs-scripts.com/142770276.js',
      production: 'https://js-eu1.hs-scripts.com/139578958.js',
    };

    return urls[env] ?? urls['local'];
  }, []);

  return (
    <RootWrapper>
      {account?.email && account?.hubSpotIdentificationToken && (
        <HubSpotChat
          email={account.email}
          token={account.hubSpotIdentificationToken}
          isVisible={
            !location.pathname.includes('/admin') &&
            !location.pathname.includes('/attendance-allowance')
          }
          trackingUrl={hubspotTrackingUrl}
          isApple={isApp && isApple}
        />
      )}
      <NavBarTemplate
        navBar={
          isAuthenticated && (
            <NavBar
              role={account?.accountRole?.name || 'User'}
              name={`${account?.firstName} ${account?.lastName || ''}`}
              fallback={`${account?.firstName?.[0]}${
                account?.lastName?.[0] || ''
              }`}
              toHome={homePath}
              toAccountSettings='/settings'
              onSignOut={signOut}
              isMobile={isMobile}
            />
          )
        }
        banner={
          <>
            {account?.accountRole?.name === 'Care Concierge' && (
              <Banner
                content={
                  <>
                    <div>
                      You are currently signed in as a Care Concierge Member
                    </div>
                    <Tooltip content='You are able to view and modify the profile and care plan of any care circle.'>
                      <Icon icon='info-circle' fontSize={18} />
                    </Tooltip>
                  </>
                }
              />
            )}
            {!isApp && (
              <Banner
                to={`${apiUrl}/v1/app-store`}
                content={
                  <>
                    <div>
                      Download our app <u>here</u>
                    </div>
                    <Icon icon='arrow-down-to-bracket' fontSize={18} />
                  </>
                }
              />
            )}
          </>
        }
        content={currentOutlet}
      />
    </RootWrapper>
  );
};

const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

Root.displayName = 'Root';
export default Root;
