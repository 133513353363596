// react
import { useCallback, useEffect, useMemo, useState } from 'react';

// components
import { RecipientProfileAbout } from '@karehero/llama';

// hooks
import { useCareCircle, useCareProfile } from 'hooks';

// store
import {
  useCreateManyAnswerMutation,
  useLazyGetQuestionsQuery,
} from 'store/api/careProfile';

// models
import {
  CareProfileAnswer as Answer,
  CareProfileQuestion as Question,
  MemberRole,
} from '@karehero/models';

/**
 * KeyInformation shows the KeyInformationPage component.
 */
const KeyInformation = () => {
  // state
  const [flatAnswers, setFlatAnswers] = useState<any>({});
  const [isEditing, setIsEditing] = useState(false);
  const [keyInformation, setKeyInformation] = useState<Question[]>([]);
  const [nextOfKin, setNextOfKin] = useState<Question[]>([]);
  const [professionalContacts, setProfessionalContacts] = useState<Question[]>(
    [],
  );
  const [isLoaded, setIsLoaded] = useState(false);

  // hooks
  const { currentCareCircle: careCircle, currentCareCircleMember } =
    useCareCircle();
  const { answers } = useCareProfile();
  const [getQuestions] = useLazyGetQuestionsQuery();
  const [createManyAnswer] = useCreateManyAnswerMutation();

  // methods
  const handleSave = useCallback(() => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    const careProfileAnswers: Answer[] = [];
    Object.keys(flatAnswers).forEach((key) => {
      if (!careCircle?.careProfile?.id) return;

      careProfileAnswers.push({
        careProfileId: careCircle.careProfile.id,
        careProfileQuestionId: key,
        value: flatAnswers[key],
      });
    });

    createManyAnswer(careProfileAnswers);
    setIsEditing(false);
  }, [careCircle?.careProfile?.id, flatAnswers, isEditing, createManyAnswer]);

  // memos
  const careRecipient = useMemo(() => {
    return careCircle?.careRecipientAccount;
  }, [careCircle]);

  // effects
  useEffect(() => {
    if (!isLoaded && Object.keys(answers).length > 0) {
      const careProfileAnswers: any = {};
      Object.keys(answers).forEach((form) => {
        Object.keys(answers[form]).forEach((key) => {
          if (!careCircle?.careProfile?.id) return;
          careProfileAnswers[key] = answers[form][key];
        });
      });
      setFlatAnswers(careProfileAnswers);
      setIsLoaded(true);
    }
  }, [answers, isLoaded, careCircle?.careProfile?.id]);

  useEffect(() => {
    if (!careCircle?.id) return;

    const fetchKeyInformation = async () => {
      const res1 = await getQuestions([
        'care-recipient-is-over-65',
        'care-recipient-phone-number',
        'care-recipient-nationality',
        'care-recipient-first-language',
      ]);
      if (!res1.data) return;
      setKeyInformation(res1.data);
    };

    const fetchNextOfKin = async () => {
      const res2 = await getQuestions(['care-giver-is-next-of-kin']);
      if (!res2.data) return;
      setNextOfKin(res2.data);
    };
    const fetchProfessionalContacts = async () => {
      const res3 = await getQuestions([
        'professional-1-type',
        'professional-1-first-name',
        'professional-1-last-name',
        'professional-1-phone-number',
        'professional-1-email',
        'professional-2-type',
        'professional-2-first-name',
        'professional-2-last-name',
        'professional-2-phone-number',
        'professional-2-email',
        'professional-3-type',
        'professional-3-first-name',
        'professional-3-last-name',
        'professional-3-phone-number',
        'professional-3-email',
      ]);
      if (!res3.data) return;
      setProfessionalContacts(res3.data);
    };

    (async () => {
      await fetchKeyInformation();
      await fetchNextOfKin();
      await fetchProfessionalContacts();
    })();
  }, [careCircle?.id, getQuestions]);

  return (
    <RecipientProfileAbout
      fallback={`${careRecipient?.firstName?.[0] || ''}${
        careRecipient?.lastName?.[0] || ''
      }`}
      name={`${careRecipient?.firstName || ''}`}
      keyInformation={keyInformation}
      nextOfKin={nextOfKin}
      professionalContacts={professionalContacts}
      answers={flatAnswers}
      setAnswers={setFlatAnswers}
      onSave={handleSave}
      isEditing={isEditing}
      isDisabled={
        !currentCareCircleMember.roles.find(
          (role) => role.name === MemberRole.PrimaryCaregiver,
        )
      }
    />
  );
};

export default KeyInformation;
