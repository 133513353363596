var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
import styled from 'styled-components';
// components
import { Title } from "../../atoms/Title/Title";
import { Button, Dialog } from "../../atoms";
import { AccountList, CareCircleInvite } from "../../organisms";
/**
 * CareCircleMemberAll is the page for the Care Circle.
 */
export const CareCircleMemberAll = ({ careCircle, onPreview, onInvite, }) => {
    // state
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    // methods
    const handleInvite = useCallback((email) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield onInvite(email);
            if (!res || 'error' in res)
                return;
            setIsInviteOpen(false);
        }
        catch (e) {
            console.error(e);
        }
    }), [onInvite]);
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsx(BackButton, { children: _jsx(Button, Object.assign({ ariaLabel: 'Back', variant: 'secondary', iconLeft: 'chevron-left', onPress: () => window.history.back() }, { children: "Back" })) }), _jsxs(Header, { children: [_jsx(Title, { children: "Care Circle" }), _jsx(StyledButton, Object.assign({ ariaLabel: 'sections', iconLeft: 'plus', onPress: () => setIsInviteOpen(true), size: 'sm' }, { children: "Invite others" }))] }), _jsx(Text, { children: "Your network of family or friends who also care for your loved one." }), careCircle && (careCircle === null || careCircle === void 0 ? void 0 : careCircle.careCircleMembers) && (_jsx(AccountList, { members: careCircle === null || careCircle === void 0 ? void 0 : careCircle.careCircleMembers, onPreview: onPreview, onInvite: handleInvite }))] }), _jsx(Dialog, Object.assign({ isOpen: isInviteOpen, setIsOpen: setIsInviteOpen }, { children: _jsx(CareCircleInvite, { onInvite: handleInvite, onCancel: () => setIsInviteOpen(false) }) }))] }));
};
const Wrapper = styled.div `
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const Header = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledButton = styled(Button) `
  margin-bottom: ${(props) => props.theme.spacing[36]};
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: left;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const BackButton = styled.div `
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[20]};
`;
CareCircleMemberAll.displayName = 'CareCircleMemberAll';
export default CareCircleMemberAll;
