import { useContext, useEffect, useState } from 'react';

import Column from '../components/primitives/Column';

import Banner from '../features/search/Banner';
import SearchResultsWithControls from '../features/search/SearchResultsWithControls';

import useProviderSearch from '../hooks/useProviderSearch';
import { BrowserLocationContext } from '../context/BrowserLocationContext';
import { SearchResultsContext } from '../context/SearchResultsContext';
import { SearchContext } from '../context/SearchContext';

export default function Search() {
  const { search: location } = useContext(BrowserLocationContext);
  const { pristine } = useContext(SearchResultsContext);
  const [firstLoad, setFirstLoad] = useState(true);
  const { search } = useProviderSearch();
  const { setSearchTerm } = useContext(SearchContext);

  useEffect(() => {
    if (firstLoad && location && pristine) {
      setFirstLoad(false);

      setSearchTerm(location);
      search(location);
    }
  }, [firstLoad, setFirstLoad, search, setSearchTerm, location, pristine]);

  return (
    <>
      <Column>
        <Banner />
        <SearchResultsWithControls />
      </Column>
    </>
  );
}
