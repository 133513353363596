import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// mux
import MuxPlayer from '@mux/mux-player-react';
// portabletext
import { PortableText } from '@portabletext/react';
// utils
import { buildImageUrl } from "../../../utils/sanity";
const ptComponents = {
    marks: {
        link: (props) => (_jsx(StyledLink, Object.assign({ href: props.value.href, target: '_blank', rel: 'noopener noreferrer' }, { children: props.children }))),
    },
    block: {
        h1: (props) => _jsx(Heading1, { children: props.children }),
        h2: (props) => _jsx(Heading2, { children: props.children }),
        h3: (props) => _jsx(Heading3, { children: props.children }),
        h4: (props) => _jsx(Heading4, { children: props.children }),
        h5: (props) => _jsx(Heading5, { children: props.children }),
        h6: (props) => _jsx(Heading6, { children: props.children }),
    },
    types: {
        image: (props) => {
            var _a, _b;
            return (_jsx(BlockImage, { children: _jsx("img", { src: buildImageUrl((_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b.url), alt: 'related' }) }));
        },
        'mux.video': (props) => {
            var _a, _b;
            return (_jsx(StyledMuxPlayer, { playbackId: (_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b.playbackId }));
        },
    },
};
export const SanityContent = ({ content }) => {
    // hooks
    const location = useLocation();
    // memos
    const ptDynamicComponents = useMemo(() => {
        return Object.assign(Object.assign({}, ptComponents), { marks: Object.assign(Object.assign({}, ptComponents.marks), { internalLink: (props) => (_jsx(StyledInternalLink, Object.assign({ to: `/library/${props.value.slug.current}` }, { children: props.children }))) }) });
    }, [location]);
    return (_jsx(Wrapper, { children: content && (_jsx(PortableText, { value: content, components: ptDynamicComponents })) }));
};
const Wrapper = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledMuxPlayer = styled(MuxPlayer) `
  border-radius: 8px;
  overflow: hidden;
`;
const Heading1 = styled.h1 `
  font: ${(props) => props.theme.typography.heading['7xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Heading2 = styled.h2 `
  font: ${(props) => props.theme.typography.body.xxl.medium};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Heading3 = styled.h3 `
  font: ${(props) => props.theme.typography.body.xl.medium};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Heading4 = styled.h4 `
  font: ${(props) => props.theme.typography.body.lg.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Heading5 = styled.h5 `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Heading6 = styled.h6 `
  font: ${(props) => props.theme.typography.body.sm.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledLink = styled.a `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.body.md.medium};
  text-decoration: underline;
`;
const StyledInternalLink = styled(Link) `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.body.md.medium};
  text-decoration: underline;
`;
const BlockImage = styled.div `
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-top: ${(props) => props.theme.spacing[16]};
  margin-bottom: ${(props) => props.theme.spacing[36]};
  & > img {
    width: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
SanityContent.displayName = 'SanityContent';
export default SanityContent;
