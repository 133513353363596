// react
import { createSlice, isRejected } from '@reduxjs/toolkit';

export interface ToastQueueItem {
  title: string;
  description: string;
  variant?: 'success' | 'warning' | 'error' | 'info' | 'plain';
}

export interface ApiResponse {
  data: { message: string };
}

export interface ToastState {
  toastQueue: ToastQueueItem[];
}

const toParagraph = (str: string): string => {
  const firstLetter = str?.[0].toUpperCase();
  const rest = str?.slice(1);
  return firstLetter + rest + '.';
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    toastQueue: [],
  } as ToastState,
  reducers: {
    newToast: (state, action) => {
      state.toastQueue = [action.payload];
    },
    removeToast: (state) => {
      state.toastQueue = state.toastQueue.slice(1);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected(), (state, action) => {
      const status = (action.payload as any)?.status;
      if (status === 202) return;
      if (status === 409) return;
      if (status === 410) return;
      if (status === 401) {
        state.toastQueue = [
          {
            title: 'Error',
            description: toParagraph(
              'Your session has expired. Please sign in again',
            ),
            variant: 'error',
          },
        ];
        return;
      }

      const description = (action.payload as ApiResponse)?.data?.message;
      if (!description) {
        return;
      }
      state.toastQueue = [
        {
          title: 'Error',
          description: toParagraph(description),
          variant: 'error',
        },
      ];
    });
  },
});

export const { newToast, removeToast } = toastSlice.actions;

export const getToastQueue = (state: any) => state.toast.toastQueue;

export default toastSlice.reducer;
