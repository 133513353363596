import { styled } from '../styling';

const Column = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  ${({ grow }) => grow && 'flex-grow: 1;'};
  ${({ alignCentre }) => alignCentre && 'align-items: center;'};
`;

export default Column;
