import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Card, Divider, Icon, Title } from "../../atoms";
import { ProfileSummary } from "../../molecules";
import { CardContainer } from "../../templates";
/**
 * CareProfile is the page for the care plan.
 */
export const CareProfile = ({ careRecipient, isCareCircle, }) => {
    var _a, _b;
    // memos
    const cards = useMemo(() => [
        {
            title: 'Attendance Allowance',
            to: '/attendance-allowance',
            color: 'green',
            icon: 'circle-sterling',
        },
        {
            title: 'Care Plan',
            to: '/care-plan',
            color: 'red',
            icon: 'heart',
        },
        ...(isCareCircle
            ? [
                {
                    title: 'Care Circle',
                    to: '/care-circle',
                    color: 'yellow',
                    icon: 'circle-plus',
                },
            ]
            : []),
    ], [isCareCircle]);
    const altCards = useMemo(() => [
        {
            title: 'Key Information',
            to: '/key-information',
            color: 'white',
            icon: 'circle-info',
        },
    ], [isCareCircle]);
    return (_jsxs(Wrapper, { children: [_jsxs(Header, { children: [_jsx(Title, { children: "Care Profile" }), _jsx(ProfileSummary, Object.assign({}, careRecipient, { name: `${(careRecipient === null || careRecipient === void 0 ? void 0 : careRecipient.firstName) || ''} ${(careRecipient === null || careRecipient === void 0 ? void 0 : careRecipient.lastName) || ''}`, role: 'Your Loved One', fallback: `${((_a = careRecipient === null || careRecipient === void 0 ? void 0 : careRecipient.firstName) === null || _a === void 0 ? void 0 : _a[0]) || ''} ${((_b = careRecipient === null || careRecipient === void 0 ? void 0 : careRecipient.lastName) === null || _b === void 0 ? void 0 : _b[0]) || ''}` }))] }), _jsxs(Body, { children: [_jsx(CardContainer, Object.assign({ maxColumns: 1, size: 'md' }, { children: cards.map((card, index) => (_jsx(StyledLink, Object.assign({ to: card.to }, { children: _jsx(Card, Object.assign({ color: card.color, isPaddingSm: true }, { children: _jsxs(CardContent, { children: [_jsxs(Content, { children: [_jsx(Icon, { icon: card.icon }), _jsx("span", { children: card.title })] }), _jsx(Icon, { icon: 'chevron-right' })] }) })) }), index))) })), _jsx(Divider, { isOr: false }), _jsx(CardContainer, Object.assign({ maxColumns: 1, size: 'md' }, { children: altCards.map((card, index) => (_jsx(StyledLink, Object.assign({ to: card.to }, { children: _jsx(Card, Object.assign({ color: card.color, isPaddingSm: true }, { children: _jsxs(CardContent, { children: [_jsxs(Content, { children: [_jsx(Icon, { icon: card.icon }), _jsx("span", { children: card.title })] }), _jsx(Icon, { icon: 'chevron-right' })] }) })) }), index))) }))] })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const StyledLink = styled(Link) `
  text-decoration: none;
`;
const CardContent = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Content = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.md.medium};
`;
CareProfile.displayName = 'CareProfile';
export default CareProfile;
