// react
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { CareCircleMemberAll } from '@karehero/llama';

// hooks
import { useCareCircle } from 'hooks';

// store
import { useInviteCareCircleMutation } from 'store/api/careCircle';

/**
 * CareCircleMember shows the CareCircleMemberPage component.
 */
const CareCircleMember = () => {
  // memos
  const { currentCareCircle } = useCareCircle();

  // hooks
  const [inviteCareCircle] = useInviteCareCircleMutation();
  const navigate = useNavigate();

  // methods
  const handleInvite = useCallback(
    async (email: string) => {
      try {
        return await inviteCareCircle({ email }).unwrap();
      } catch (e) {
        console.error(e);
      }
    },
    [inviteCareCircle],
  );

  return (
    <CareCircleMemberAll
      careCircle={currentCareCircle}
      onPreview={(member) => navigate(`/care-circle/member/${member.id}`)}
      onInvite={handleInvite}
    />
  );
};

export default CareCircleMember;
