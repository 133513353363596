import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
// components
import { Title } from "../../atoms";
import { Editor } from "..";
import { FloatingActions } from "../../molecules";
// editor
import { createFieldHelper, FieldType, } from "../EditorFields/fieldHelper";
export const fields = createFieldHelper({
    email: {
        label: 'Email',
        field: {
            type: FieldType.Email,
        },
    },
});
/**
 * CareCircleInvite is an editor for creating a new care profile.
 */
export const CareCircleInvite = ({ onInvite, onCancel, }) => {
    const [value, setValue] = useState({ email: '' });
    return (_jsxs(Wrapper, { children: [_jsxs(Header, { children: [_jsx(Title, { children: "Invite to Care Circle" }), _jsx(Description, { children: "Once invited your care circle member can join conversations with your care expert, view key information, and access the library resources." })] }), _jsx(Editor, { fields: fields, value: value, onChange: setValue, isActionsDisabled: true, isWithArrows: true }), _jsx(ButtonWrapper, { children: _jsx(FloatingActions, { actions: [
                        {
                            label: 'Cancel',
                            onPress: onCancel,
                            variant: 'secondary',
                        },
                        {
                            label: 'Send email invite',
                            onPress: () => onInvite(value.email),
                        },
                    ] }) })] }));
};
const Wrapper = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[12]};
  width: 100%;
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const ButtonWrapper = styled.div `
  position: relative;
`;
CareCircleInvite.displayName = 'CareCircleInvite';
export default CareCircleInvite;
