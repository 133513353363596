// react
import { useEffect } from 'react';

// directory
import { BrowserLocationProvider } from './context/BrowserLocationContext';
import { CareTypeProvider } from './context/CareTypeContext';
import { ProviderFilterProvider } from './context/ProviderFilterContext';
import { SearchResultsProvider } from './context/SearchResultsContext';
import { ActiveProviderProvider } from './context/ActiveProviderContext';
import { GlobalSettingsProvider } from './context/GlobalSettingsContext';
import { SearchContextProvider } from './context/SearchContext';

import Router from './Router';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

export default function App() {
  // hooks
  const { track } = useMixpanel();

  // effects
  useEffect(() => {
    track(MixpanelEvent.DirectoryViewed, {});
  }, [track]);

  return (
    <GlobalSettingsProvider>
      <BrowserLocationProvider>
        <SearchContextProvider>
          <ProviderFilterProvider>
            <CareTypeProvider>
              <SearchResultsProvider>
                <ActiveProviderProvider>
                  <Router />
                </ActiveProviderProvider>
              </SearchResultsProvider>
            </CareTypeProvider>
          </ProviderFilterProvider>
        </SearchContextProvider>
      </BrowserLocationProvider>
    </GlobalSettingsProvider>
  );
}
