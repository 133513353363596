// react
import { createSlice, isRejected } from '@reduxjs/toolkit';
import iamApi from 'store/api/iam';

// store
import { kindeEnvironmentUrl } from 'store/api';

// models
import { Account, LdContext } from '@karehero/models';

// lib
import { createLdContext } from 'launchDarkly';

export interface IamState {
  isAuthenticated: boolean;
  account: Account | null;
  ldContext: LdContext | null;
}

export const iamSlice = createSlice({
  name: 'iam',
  initialState: {
    isAuthenticated: false,
    account: null,
  } as IamState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isRejected(), (state, action) => {
        const status = (action.payload as any)?.status;
        if (status === 401) {
          state.isAuthenticated = false;
          state.account = null;
          state.ldContext = null;
        }
      })
      .addMatcher(
        iamApi.endpoints.createByInvite.matchFulfilled,
        (state, { payload }) => {
          state.account = payload;
          state.isAuthenticated = true;
          state.ldContext = createLdContext(payload);
        },
      )
      .addMatcher(
        iamApi.endpoints.signInKinde.matchFulfilled,
        (state, { payload }) => {
          state.account = payload;
          state.isAuthenticated = true;
          state.ldContext = createLdContext(payload);
        },
      )
      .addMatcher(iamApi.endpoints.signInKinde.matchRejected, (state) => {
        state.ldContext = null;

        let kindeLogoutUrl = new URL(`https://${kindeEnvironmentUrl}/logout`);
        const queryParams = new URLSearchParams({
          redirect: `${window.location.origin}/sign-in`,
        });
        kindeLogoutUrl.search = queryParams.toString();

        window.location.href = kindeLogoutUrl.toString();
      })
      .addMatcher(
        iamApi.endpoints.migrateAuthenticate.matchFulfilled,
        (state, { payload }) => {
          state.account = payload;
          state.isAuthenticated = true;
          state.ldContext = createLdContext(payload);
        },
      )
      .addMatcher(iamApi.endpoints.signOut.matchFulfilled, (state) => {
        state.isAuthenticated = false;
        state.account = null;
        state.ldContext = null;
      })
      .addMatcher(
        iamApi.endpoints.updateIsOnboarded.matchFulfilled,
        (state) => {
          if (state.account) state.account.isOnboarded = true;
        },
      )
      .addMatcher(
        iamApi.endpoints.createMember.matchFulfilled,
        (state, { payload }) => {
          state.account = payload;
          state.isAuthenticated = true;
          state.ldContext = createLdContext(payload);
        },
      )
      .addMatcher(
        iamApi.endpoints.updateCareCircleNames.matchFulfilled,
        (state, { payload }) => {
          if (state.account) {
            state.account.firstName = payload.caregiverFirstName;
          }
        },
      );
  },
});

export const getIsAuthenticated = (state: any) => state.iam.isAuthenticated;
export const getAccount = (state: any) => state.iam.account;
export const getIsOnboarded = (state: any) => state.iam.isOnboarded;

export default iamSlice.reducer;
